@import url(https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;700&display=swap);
*{box-sizing:border-box;margin:0;padding:0}html{height:100%;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body{background-color:#f4f6f8;height:100%}a{text-decoration:none}#root{height:100%}
/* styles */

* {
  margin: 0;
  padding: 0;
}

.body {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 10pt;
  color: #7F7F7F;
  width: 793pt;
  margin: 30pt auto;
  background-color: #fff;
}

.header {
  color: #595959;
  width: 100%;
  background-color: #edeeef;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 7.5pt 50pt 22pt;
}
.logo {
  padding: 22pt 50pt;
}
.nexus-logo {
  width: 100px;
}

.nexus-logo img {
  width: 100%;
}
.title {
  font-size: 42pt;
  font-weight: 700;
  padding-bottom: 10pt;
  border-bottom: 3pt solid #a6a6a6;
}

.photo {
  padding-bottom: 6pt;
  border-bottom: 3pt solid #a6a6a6;
}

.img {
  width: auto;
  height: auto;
  max-width: 100pt;
  max-height: 150pt;
}

.gap {
  height: 18pt;
}

.content {
  display: flex;
  min-height: 78%;
}

.left {
  width: 35%;
  background-color: #edeeef;
  padding: 22pt 12pt 50pt 50pt;
}

.right {
  width: 65%;
  padding: 22pt 12pt 50pt 50pt;
}

.sec_title {
  color: #595959;
  font-size: 14pt;
  font-weight: 700;
  margin-bottom: 10pt;
}

.featured {
  font-size: 12pt;
  display: flex;
  align-items: center;
  margin-bottom: 5pt;
}

.icon {
  display: inline-block;
  width: 50pt;
}

.lang {
  min-width: 50%;
}

.data {
  font-size: 9pt;
}

.section {
  margin-bottom: 50pt;
}

.section:last-child {
  margin-bottom: 10pt;
}

.section_columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20pt;
}

.section_columns-title {
  color: #595959;
  font-size: 14pt;
  font-weight: 700;
  text-transform: uppercase;
}

.section_columns-subtitle {
  font-size: 12pt;
  margin-bottom: 5pt;
}

.section_columns-left {
  width: 20%;
  font-size: 9pt;
  line-height: 12pt;
}

.section_columns-right {
  width: 80%;
  padding-left: 10pt;
}

ul {
  padding-left: 12pt;
}

.rdw-editor-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

